import { action, atom, onMount, task } from "nanostores";
import { OramaClient } from "@oramacloud/client";

export interface SearchResult {
  id: string;
  title: string;
  body: string;
  path: string;
}

export const $commandbarSearchResults = atom<SearchResult[]>([]);

const oramaClient = new OramaClient({
  endpoint: "https://cloud.orama.run/v1/indexes/ekonomifyi-mmr5c8",
  api_key: "9p5hjHOHqqafQxbvD2vBX9RXqOjgXPKu",
});

onMount($commandbarSearchResults, () => {
  task(async () => {
    const results = await oramaClient.search({
      term: "",
      limit: 5,
      mode: "hybrid",
    });
    $commandbarSearchResults.set(
      results.hits.map((hit: any) => {
        return {
          id: hit.document.id,
          title: hit.document.title,
          body: hit.document.body,
          path: hit.document.path,
        };
      })
    );
  });
});

export const newSearch = action(
  $commandbarSearchResults,
  "newSearch",
  (_store, query) => {
    task(async () => {
      const results = await oramaClient.search({
        term: query,
        limit: 5,
        mode: "hybrid",
      });
      $commandbarSearchResults.set(
        results.hits.map((hit: any) => {
          return {
            id: hit.document.id,
            title: hit.document.title,
            body: hit.document.body,
            path: hit.document.path,
          };
        })
      );
    });
  }
);
