import { useState } from "react";
import {
  CommandDialog,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { BookOpen, GitMerge, MedalIcon, Search } from "lucide-react";
import { newSearch } from "@/lib/stores/SearchStores";
import { $commandbarSearchResults } from "@/lib/stores/SearchStores";
import { useStore } from "@nanostores/react";

export function CommandMenu() {
  const commandbarSearchResults = useStore($commandbarSearchResults);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          placeholder="Type a command or search..."
          onValueChange={(q: any) => {
            newSearch(q);
            //   newSearch(e);
          }}
        />
        <CommandList>
          <CommandGroup heading="Menu">
            <CommandItem
              className="aria-selected:bg-secondary aria-selected:text-white"
              onSelect={() => {
                //   router.push("/wiki/pengantar/apa-itu-ekonwiki");
                setOpen(false);
              }}
            >
              <BookOpen className="mr-2 h-4 w-4" />
              <span>Wiki</span>
            </CommandItem>
            <CommandItem
              className="aria-selected:bg-secondary aria-selected:text-white"
              onSelect={() => {
                //   router.push("/soal");
                setOpen(false);
              }}
            >
              <MedalIcon className="mr-2 h-4 w-4" />
              <span>Soal</span>
            </CommandItem>
          </CommandGroup>
          <CommandGroup heading="Wiki">
            {/* <CommandEmpty>Turu</CommandEmpty> */}
            {commandbarSearchResults.map((result) => {
              return (
                <a
                  className="cursor-pointer"
                  href={result.path}
                  key={result.id}
                >
                  <CommandItem
                    className="aria-selected:bg-secondary cursor-pointer  aria-selected:text-white"
                    onSelect={() => {
                      // router.push(result.path);
                      setOpen(false);
                    }}
                  >
                    <GitMerge className="mr-2 h-4 w-4" />
                    <span>{result.title}</span>
                  </CommandItem>
                </a>
              );
            })}
            {/* {searchResults.length < 1 && <CommandEmpty />} */}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
      <button
        className=" hidden items-center text-gray-600 hover:text-gray-400 sm:flex"
        onClick={() => setOpen(!open)}
      >
        <p className="group pr-3  text-center  ">Search</p>

        <Search className=" group text-center  " size={18} />
      </button>
      <button
        className=" hs-collapse-toggle focus:ring-secondary flex items-center justify-center  gap-2  rounded-md border bg-white p-2 align-middle text-sm font-medium text-gray-700 transition-all hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-white dark:focus:ring-offset-gray-800 sm:hidden "
        onClick={() => setOpen(!open)}
      >
        <Search
          className="text-center text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500"
          size={18}
        />
      </button>
    </div>
  );
}
